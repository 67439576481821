import axios from "axios";
import { tokenExpired } from "./auth";

export const setDefaultHeader = token => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  tokenExpired(token);
};

export const getApps = async () => {
  const {
    data: { data, errors, message }
  } = await axios.get(window.env.API_URL + "apps");
  return data;
};
