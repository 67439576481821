import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import ApplicationModel from "./models/ApplicationModel";

export default class ApplicationStore {
  @observable
  applications = new ApplicationModel();

  @computed
  get apps() {
    return this.applications.applications;
  }
}
