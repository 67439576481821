const { DEFAULT_LANGUAGE = "nl_BE", MAP_WMTS_TILE_LAYER_DEFAULT_ZOOM = 15, SEARCH_ENABLED = true } = window?.env ?? {};

export const APP_LOCALES = {
  NL: "nl",
  FR: "fr"
};

export const DEFAULT_LOCALE = DEFAULT_LANGUAGE.includes("nl") ? APP_LOCALES.NL : APP_LOCALES.FR;

export const MAP_TYPES = {
  WMTS: "wmts",
  TILE: "tile"
};

export const MAP_TILE_MATRIX = {
  GOOGLE: "GoogleMapsVL",
  G20: "g20"
};

export const MAP_TILE_FORMAT = {
  PNG: "image/png"
};

export const MAP_CONFIG = {
  zoom: MAP_WMTS_TILE_LAYER_DEFAULT_ZOOM || 15
};

export const HEADER_CONFIG = {
  SEARCH_ENABLED: SEARCH_ENABLED || true,
  SEARCH_RESULT_COUNT: 8
};

export const SIDEBAR_CONFIG = {
  width: 350
};

export const Z_INDEXES = {
  MAP_PUBLIC_WMTS_TILE_LAYER: 100,
  MAP_PUBLIC_TILE_LAYER: 101,
  MAP_PUBLIC_WMTS_TILE_LAYER_ORG: 102,
  MAP_ORBIT_LOGO: 1000
};
