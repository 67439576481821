import axios from "axios";
import jwt_decode from "jwt-decode";

export const setDefaultHeader = token => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

export const login = async (email, password) => {
  let success = false;
  let token = null;
  let user = null;

  try {
    let {
      data: { data }
    } = await axios.post(window.env.API_URL + "login", {
      appId: process.env.REACT_APP_APP_ID,
      email: email,
      password: password
    });
    if (!data.token) success = false;
    else {
      success = true;
      token = data.token;
      user = data.user;
      setDefaultHeader(token);
    }
  } catch (error) {
    success = false;
  }
  return {
    success,
    token,
    user
  };
};

export const logout = async () => {
  let {
    data: { data }
  } = await axios.post(window.env.API_URL + "logout");
  setDefaultHeader(null);
  return true;
};

export const forgotPassword = async email => {
  try {
    let { data } = await axios.post(window.env.API_URL + "lost-password", {
      email
    });
    return data;
  } catch (error) {
    console.error("ERROR: forgotPassword ", error.toString());
    throw error;
  }
};

export const setPassword = async password => {
  try {
    let {
      data: { data }
    } = await axios.post(window.env.API_URL + "set-password", {
      password
    });
    return data;
  } catch (error) {
    console.error("ERROR: setPassword ", error.toString());
    throw error;
  }
};

export const validateEmailToken = async token => {
  try {
    let {
      data: { data }
    } = await axios.get(`${window.env.API_URL}verify/${token}`);

    setDefaultHeader(token);
    return data;
  } catch (error) {
    console.error("ERROR: validateEmailToken ", error.toString());
    throw error;
  }
};

export const tokenExpired = token => {
  let json = jwt_decode(token);
  let expTime = json.exp * 1000;
  let currentTime = Date.now();
  return currentTime >= expTime;
};

/**export const login = async (username, password) => {
  return true;
};

export const logout = async () => {
  return true;
};**/
