import {
  AppBar,
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StoresContext } from "contexts";
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { ROUTE_FORGOTPASSWORD } from "../../../routes/RouteList";
import messages from "./messages";
import useRestPasswordStyles from "./ResetPasswordStyles";

const ResetPasswordSuccess = ({ classes, match, intl: { formatMessage } }) => {
  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.resetpassSuccessTitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <div>
          <Typography>{formatMessage(messages.resetpassSuccess)}</Typography>
        </div>
      </div>
    </Paper>
  );
};

export default injectIntl(
  withStyles(useRestPasswordStyles)(ResetPasswordSuccess)
);
