import { Fab, Menu, MenuItem } from "@material-ui/core";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import useHeaderFabStyles from "./HeaderFabStyles";
import { withStyles } from "@material-ui/styles";

const HeaderFab = hooksObserver(
  ({
    classes,
    fabtitle,
    fabicon,
    fabaction = null,
    fabactions = [],
    fabmenuid = null
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleMenu(event) {
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    return (
      <div>
        <Fab
          aria-owns={fabaction === null && open ? fabmenuid : undefined}
          aria-haspopup={fabaction === null && "true"}
          onClick={(fabaction === null && handleMenu) || fabaction}
          variant="extended"
          color="inherit"
          className={classes.customfab}
        >
          {fabtitle}
          {fabicon}
        </Fab>
        {fabaction === null && (
          <Menu
            id={fabmenuid}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={open}
            onClose={handleClose}
          >
            {fabactions.map(action => (
              <MenuItem
                key={action.title}
                onClick={() => {
                  action.funct();
                  handleClose();
                }}
              >
                {action.title}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    );
  }
);

export default injectIntl(withStyles(useHeaderFabStyles)(HeaderFab));
