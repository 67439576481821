import { createMuiTheme } from "@material-ui/core/styles";

const baseTheme = {
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    }
  },
  shape: {
    borderRadius: 8
  }
};

let light = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3"
    },
    secondary: {
      main: "#232f3e"
    },
    tertiary: {
      main: "#18202c"
    },
    icon: {
      main: "#000",
      sec: "#00ACC1"
    },
    success: {
      main: "#43a047"
    },
    danger: {
      main: "#d32f2f"
    },
    alert: {
      main: "#ffa000"
    }
  },
  baseTheme
});

let dark = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3"
    },
    secondary: {
      main: "#232f3e"
    },
    tertiary: {
      main: "#18202c"
    },
    icon: {
      main: "#FFF",
      sec: "#00ACC1"
    },
    success: {
      main: "#43a047"
    },
    danger: {
      main: "#d32f2f"
    },
    alert: {
      main: "#ffa000"
    }
  },
  baseTheme
});

light = {
  ...light,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c"
      }
    },
    MuiButton: {
      label: {
        textTransform: "initial"
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none"
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: light.spacing(1)
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: light.palette.common.white
      }
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        margin: "0 16px",
        minWidth: 0,
        [light.breakpoints.up("md")]: {
          minWidth: 0
        }
      },
      labelContainer: {
        padding: 0,
        [light.breakpoints.up("md")]: {
          padding: 0
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: light.spacing(1)
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: light.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        borderBottom: 0,
        "&:last-child": {
          paddingRight: 8
        }
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...light.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

dark = {
  ...dark,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c"
      }
    },
    MuiButton: {
      label: {
        textTransform: "initial"
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none"
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: dark.spacing(1)
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: dark.palette.common.white
      }
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        margin: "0 16px",
        minWidth: 0,
        [dark.breakpoints.up("md")]: {
          minWidth: 0
        }
      },
      labelContainer: {
        padding: 0,
        [dark.breakpoints.up("md")]: {
          padding: 0
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: dark.spacing(1)
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: dark.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        borderBottom: 0,
        "&:last-child": {
          paddingRight: 8
        }
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...dark.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

export { light, dark };
