import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import { IntlProvider } from "react-intl";
import { Router, useParams } from "react-router";
import { translationMessages } from "./i18n";
import { inject } from "mobx-react";
import { observer as hooksObserver } from "mobx-react-lite";
import AppRoute from "./routes/AppRoute";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { light } from "./layout/theme.js";

const App = hooksObserver(({ routingStore, uiStore: { locale, setLocale } }) => {
  const [historyState, setHistoryState] = useState();

  useEffect(() => {
    if (routingStore && !historyState) {
      const history = syncHistoryWithStore(browserHistory, routingStore);
      setHistoryState(history);
    }
  }, [routingStore, historyState]);

  const browserHistory = createBrowserHistory();

  if (!historyState) {
    return null;
  }
  return (
    <IntlProvider locale={locale} messages={translationMessages[locale]}>
      <Router history={historyState}>
        <ThemeApp />
      </Router>
    </IntlProvider>
  );
});

const ThemeApp = hooksObserver(() => {
  return (
    <MuiThemeProvider theme={light}>
      <AppRoute />
    </MuiThemeProvider>
  );
});

export default inject("routingStore", "uiStore", "userStore")(App);
