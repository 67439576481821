import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import {
  forgotPassword,
  login,
  logout,
  validateEmailToken,
  setPassword
} from "../services/auth";

export default class AuthStore {
  routerStore = null;

  constructor(routerStore) {
    this.routerStore = routerStore;
  }
  @persist
  @observable
  isLoggedIn = false;

  @persist("object")
  @observable
  user = {};

  @persist
  @observable
  token = null;

  /**
   * returns logged in status
   */
  @action
  doLogin = async (email, password) => {
    const { success, user, token } = await login(email, password);
    if (success) {
      this.user = user;
      this.token = token;
    }
    this.isLoggedIn = success;
    return this.isLoggedIn;
  };

  /**
   * returns logged in status
   */
  @action
  doLogout = async () => {
    this.isLoggedIn = !(await logout());
    this.user = {};
    this.token = null;
    return this.isLoggedIn;
  };

  forgotPassword = async email => {
    const result = await forgotPassword(email);
    return result;
  };

  validateEmailToken = async token => {
    const result = await validateEmailToken(token);
    return result;
  };

  @action
  setPassword = async password => {
    const result = await setPassword(password);
    return result;
  };
}
