import React, { Fragment } from "react";
import { Redirect, Route } from "react-router";
import BasePage from "../containers/base/BasePage";
import { withErrorBoundary } from "../containers/partials/error-boundary/ErrorBoundary";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { ROUTE_BASE, ROUTE_FORGOTPASSWORD, ROUTE_RESETPASSWORD } from "./RouteList";

const AppRoute = () => {
  return (
    <Fragment>
      <PublicRoutes>
        <Route exact path={[ROUTE_FORGOTPASSWORD, ROUTE_RESETPASSWORD]} component={BasePage} />
        <Redirect to={ROUTE_FORGOTPASSWORD} />
      </PublicRoutes>
      <PrivateRoutes>
        <Route exact path={[ROUTE_BASE]} component={BasePage} />
        <Redirect to={ROUTE_BASE} />
      </PrivateRoutes>
    </Fragment>
  );
};

export default withErrorBoundary(AppRoute);
