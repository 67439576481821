import { action, observable, computed } from "mobx";

import { getApps } from "../../services/api";

class ApplicationModel {
  @observable
  _applications = [];

  @observable
  activeapplication = null;

  @computed
  get applications() {
    return this._applications;
  }

  @action
  setApplications = value => {
    this._applications = value;
  };

  @action
  setActiveApplication = value => {
    this.activeapplication = value;
  };

  @action
  loadApplications = async () => {
    const result =
      this._applications.length > 0 ? this._applications : await getApps();

    this.setApplications(result);
  };
}

export default ApplicationModel;
