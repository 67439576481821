import { AppBar, Button, Grid, Paper, TextField, Toolbar, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { ROUTE_FORGOTPASSWORD, ROUTE_RESETPASSWORD_MESSAGE } from "../../../routes/RouteList";
import messages from "./messages";
import useRestPasswordStyles from "./ResetPasswordStyles";
import { withStyles } from "@material-ui/styles";

const hasWhiteSpace = s => /\s/g.test(s);

const checkIfValid = password => {
  return password.length > 4 && password.length < 42 && !hasWhiteSpace(password);
};

const ResetPassword = hooksObserver(({ classes, match, intl: { formatMessage } }) => {
  const {
    routingStore: { push },
    uiStore: { locale },
    authStore: { validateEmailToken, setPassword }
  } = useContext(StoresContext);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [isPassword1Valid, setIsPassword1Valid] = useState(true);
  const [isPassword2Valid, setIsPassword2Valid] = useState(true);

  const [arePasswordsEqual, setArePasswordsEqual] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  const [sending, setSending] = useState(false);

  useEffect(() => {
    setIsPassword1Valid(checkIfValid(password1));
    setIsPassword2Valid(checkIfValid(password2));
  }, [password1, password2]);

  useEffect(() => {
    if (isPassword1Valid && isPassword2Valid) {
      const areEqual = password1 === password2;
      setArePasswordsEqual(areEqual);
      setErrorMessage(areEqual ? "" : formatMessage(messages.resetpassIdenticalErrorMessage));
    } else {
      setErrorMessage("uw wachtwoord voldoet niet aan de vereisten");
    }
  }, [isPassword1Valid, isPassword2Valid, password1, password2]);

  async function validateToken() {
    const token = match.params.token;
    try {
      const { verified } = await validateEmailToken(token);
    } catch (error) {
      setIsTokenValid(false);
      setErrorMessage(error.toString());
    }
  }

  useEffect(() => {
    validateToken();
  }, []);

  const submitForm = async event => {
    event.preventDefault();
    if (isPassword1Valid && isPassword2Valid && arePasswordsEqual) {
      try {
        setSending(true);
        await setPassword(password1);
        setSending(false);
        push(ROUTE_RESETPASSWORD_MESSAGE.replace(":lang?", locale));
      } catch (error) {
        setSending(false);
        setErrorMessage(error.toString());
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.resetpasstitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {isTokenValid ? (
          <form id="form" onSubmit={submitForm}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography color="inherit">{formatMessage(messages.resetpassdescription)}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="wachtwoord"
                  label={formatMessage(messages.resetpasspassword1)}
                  type="password"
                  error={!isPassword1Valid}
                  fullWidth
                  required
                  value={password1}
                  onChange={e => setPassword1(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="herhaalwachtwoord"
                  label={formatMessage(messages.resetpasspassword2)}
                  type="password"
                  fullWidth
                  required
                  error={!isPassword2Valid}
                  value={password2}
                  onChange={e => setPassword2(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
              {sending && (
                <Grid item>
                  <CircularProgress size={24} className={classes.progress} />
                </Grid>
              )}
              <Grid item>
                <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={submitForm}>
                  {formatMessage(messages.resetpassconfirm)}
                </Button>
              </Grid>
              <Grid item>
                <Typography>{!arePasswordsEqual && errorMessage}</Typography>
              </Grid>
            </Grid>
          </form>
        ) : (
          <div>
            <Typography>
              {formatMessage(messages.resetpassexpired1)}
              <button
                onClick={e => {
                  push(ROUTE_FORGOTPASSWORD.replace(":lang?", locale));
                }}
              >
                {formatMessage(messages.resetpassexpiredlinklabel)}
              </button>
              {formatMessage(messages.resetpassexpired2)}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(useRestPasswordStyles)(ResetPassword));
