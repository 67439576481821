import { DEFAULT_LOCALE } from "../constants";
import { observable, action } from "mobx";
import { persist } from "mobx-persist";

export default class UiStore {
  @persist
  @observable
  sideBarRightOpen = false;

  @observable
  locale = DEFAULT_LOCALE;

  @action
  setLocale = locale => {
    this.locale = locale;
  };

  @action
  setSideBarRightOpen = isOpen => {
    this.sideBarRightOpen = isOpen;
  };
}
