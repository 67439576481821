import React, { useContext } from "react";
import PropTypes from "prop-types";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { injectIntl } from "react-intl";
import { Switch, Route } from "react-router";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Icon,
  Hidden,
  Grid,
  Tooltip,
  withWidth,
  Fab,
  withStyles
} from "@material-ui/core";

import useHeaderStyles from "./HeaderStyles";
import messages from "./messages";
import HeaderTabs from "./HeaderTabs";
import HeaderFab from "../headerfab/HeaderFab";

import NotificationsIcon from "@material-ui/icons/Notifications";
import HelpIcon from "@material-ui/icons/Help";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LanguageIcon from "@material-ui/icons/Language";

import {
  ROUTE_BASE,
  ROUTE_LOGIN,
  ROUTE_FORGOTPASSWORD,
  ROUTE_RESETPASSWORD
} from "../../../routes/RouteList";

require("../../../layout/orbit.css");

const Header = hooksObserver(({ classes, intl: { formatMessage }, width }) => {
  const {
    routingStore: { push },
    authStore: { user, isLoggedIn },
    uiStore: { locale, setLocale }
  } = useContext(StoresContext);

  function logout() {
    console.log("logged out");
  }

  function switchLocale() {
    locale === "nl" ? setLocale("fr") : setLocale("nl");
    window.location.reload();
  }
  const localeTitle = locale === "nl" ? "FR" : "NL";
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={8} alignItems="center">
            <Grid item>
              <Typography variant="h6" className={classes.headerlogo}>
                <Icon className={"icon-logo-orbit-black"} />
                &nbsp;&nbsp; My Orbit
              </Typography>
            </Grid>
            <Grid item xs />
          </Grid>
          {/*<Grid item>
            <HeaderFab
              fabmenuid="app-language"
              fabtitle="NL"
              fabicon={<LanguageIcon className={classes.customfabicon} />}
              fabactions={[
                {
                  title: localeTitle,
                  funct: switchLocale
                }
              ]}
            />
            </Grid>*/}
          <Grid item>
            {/*  {(isLoggedIn && (
              <HeaderFab
                fabmenuid="app-profile"
                fabtitle={user.firstName}
                fabicon={
                  <AccountCircleIcon className={classes.customfabicon} />
                }
                fabactions={[
                  {
                    title: formatMessage(messages.logout),
                    func: logout
                  }
                ]}
              />
            )) || (
              <HeaderFab
                fabmenuid="app-profile"
                fabtitle={formatMessage(messages.login)}
                fabicon={
                  <AccountCircleIcon className={classes.customfabicon} />
                }
                fabaction={() => push(ROUTE_LOGIN)}
              />
            )} */}
          </Grid>
        </Toolbar>
      </AppBar>
      {/*<AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={8}>
            <Grid item xs>
              <Typography color="inherit" variant="h5">
                <Switch>
                  <Route exact path={ROUTE_BASE}>
                    {formatMessage(messages.dashboard)}
                  </Route>
                  <Route exact path={ROUTE_FORGOTPASSWORD}>
                    {formatMessage(messages.forgotpass)}
                  </Route>
                  <Route exact path={ROUTE_RESETPASSWORD}>
                    {formatMessage(messages.resetpass)}
                  </Route>
                </Switch>
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>*/}
      {/*<AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Switch>
          <Route
            exact
            path={["", ROUTE_BASE]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.overview),
                    route: ROUTE_BASE,
                    check: () => {
                      return location.pathname.split("/")[1] === "";
                    }
                  },
                  {
                    label: formatMessage(messages.statistics),
                    route: ROUTE_BASE,
                    check: () => {
                      return location.pathname.split("/")[1] === "statistics";
                    }
                  }
                ]}
              />
            )}
          />
          <Route
            exact
            path={[ROUTE_FORGOTPASSWORD]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.forgotpasstab),
                    route: ROUTE_FORGOTPASSWORD,
                    check: () => {
                      return (
                        location.pathname.split("/")[1] === "forgotpassword"
                      );
                    }
                  }
                ]}
              />
            )}
          />
          <Route
            exact
            path={[ROUTE_RESETPASSWORD]}
            component={() => (
              <HeaderTabs
                tabs={[
                  {
                    label: formatMessage(messages.resetpasstab),
                    route: ROUTE_RESETPASSWORD,
                    check: () => {
                      return (
                        location.pathname.split("/")[1] === "resetpassword"
                      );
                    }
                  }
                ]}
              />
            )}
          />
        </Switch>
      </AppBar>*/}
    </React.Fragment>
  );
});

export default withWidth()(injectIntl(withStyles(useHeaderStyles)(Header)));
