import React, { useContext, useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { CssBaseline, withStyles, Hidden } from "@material-ui/core";

import { Switch, Route, withRouter, useLocation, useParams } from "react-router";

import useBasePageStyles from "./BasePageStyles";
import Header from "../partials/header/Header";
import ForgotPassword from "../partials/forgotpassword/ForgotPassword";
import ResetPassword from "../partials/resetpassword/ResetPassword";

import { ROUTE_BASE, ROUTE_RESETPASSWORD, ROUTE_FORGOTPASSWORD, ROUTE_RESETPASSWORD_MESSAGE } from "../../routes/RouteList";
import ResetPasswordSuccess from "containers/partials/resetpassword/ResetPasswordSuccess";
import { StoresContext } from "contexts";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BasePage = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    uiStore: { setLocale }
  } = useContext(StoresContext);

  const params = useParams();

  useEffect(() => {
    if (params.lang) {
      setLocale(params.lang);
    }
  }, [params?.lang]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.appContent}>
        <Header />
        <main className={classes.mainContent}>
          <Switch>
            <Route exact path={ROUTE_FORGOTPASSWORD} component={ForgotPassword} />
            <Route exact path={ROUTE_RESETPASSWORD_MESSAGE} component={ResetPasswordSuccess} />
            <Route exact path={ROUTE_RESETPASSWORD} component={ResetPassword} />
          </Switch>
        </main>
      </div>
    </div>
  );
});

export default injectIntl(withStyles(useBasePageStyles)(BasePage));
