import { defineMessages } from "react-intl";

export default defineMessages({
  resetpasstitle: {
    id: "resetpassword.resetpasstitle",
    defaultMessage: "Wachtwoord instellen"
  },
  resetpassdescription: {
    id: "resetpassword.resetpassdescription",
    defaultMessage: "Het gekozen wachtwoord moet minimum 5 en maximum 42 karakters hebben en mag geen spaties bevatten"
  },
  resetpasspassword1: {
    id: "resetpassword.resetpasspassword1",
    defaultMessage: "Paswoord"
  },
  resetpasspassword2: {
    id: "resetpassword.resetpasspassword2",
    defaultMessage: "Herhaal paswoord"
  },
  resetpassconfirm: {
    id: "resetpassword.resetpassconfirm",
    defaultMessage: "Instellen"
  },
  resetpassexpired1: {
    id: "resetpassword.resetpassexpired1",
    defaultMessage: "Deze link is niet meer geldig. Klik "
  },
  resetpassexpiredlinklabel: {
    id: "resetpassword.resetpassexpiredlinklabel",
    defaultMessage: "hier"
  },
  resetpassexpired2: {
    id: "resetpassword.resetpassexpired2",
    defaultMessage: " indien u een nieuw paswoord wil aanvragen."
  },
  resetpassSuccessTitle: {
    id: "resetpassword.resetpassSuccessTitle",
    defaultMessage: "Uw wachtwoord is ingesteld. "
  },
  resetpassSuccess: {
    id: "resetpassword.resetpassSuccess",
    defaultMessage: "Je kan nu inloggen in de applicaties van orbit"
  },
  resetpassIdenticalErrorMessage: {
    id: "resetpassword.resetpassIdenticalErrorMessage",
    defaultMessage: "De wachtwoorden moeten identiek zijn"
  },
  resetpassrequirementsErrorMessage: {
    id: "resetpassword.resetpassrequirementsErrorMessage",
    defaultMessage: "uw wachtwoord voldoet niet aan de vereisten"
  }
});
