/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "error.title.message",
    defaultMessage: "Hmmn something went wrong..."
  },
  message: {
    id: "error.message.message",
    defaultMessage: "Please try again..."
  },
  retry: {
    id: "error.button.message",
    defaultMessage: "Retry"
  }
});
