import React, { useContext } from "react";
import PropTypes from "prop-types";
import { StoresContext } from "contexts";
import { Tabs, Tab } from "@material-ui/core";

function HeaderTabs(props) {
  const { tabs, match } = props;
  const {
    routingStore: { push, location }
  } = useContext(StoresContext);

  let selected = 0;
  tabs.map((tab, index) => {
    tab.check() ? (selected = index) : null;
  });

  return (
    <Tabs value={selected} textColor="inherit">
      {tabs.map(tab => (
        <Tab
          key={tab.label}
          textColor="inherit"
          label={tab.label}
          onClick={() => push(tab.route)}
        />
      ))}
    </Tabs>
  );
}

export default HeaderTabs;
