import { defineMessages } from "react-intl";

export default defineMessages({
  forgotpasstitle: {
    id: "forgotpassword.forgotpasstitle",
    defaultMessage: "Wijziging wachtwoord aanvragen"
  },
  forgotpassemail: {
    id: "forgotpassword.forgotpassemail",
    defaultMessage: "E-mail"
  },
  forgotpassconfirm: {
    id: "forgotpassword.forgotpassconfirm",
    defaultMessage: "Verzenden"
  },
  forgotpassmailsent: {
    id: "forgotpassword.forgotpassmailsent",
    defaultMessage:
      "Indien uw gegevens gekend zijn, zal u een mail ontvangen met daarin instructies om uw paswoord opnieuw in te stellen."
  },
  forgotpassmailError: {
    id: "forgotpassword.forgotpassmailError",
    defaultMessage:
      "Er is iets fout gelopen bij het versturen, controleer uw gegevens en probeer het opnieuw"
  }
});
