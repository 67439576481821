import {
  AppBar,
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { StoresContext } from "contexts";
import { observer as hooksObserver } from "mobx-react-lite";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { injectIntl } from "react-intl";
import useForgotPasswordStyles from "./ForgotPasswordStyles";
import messages from "./messages";

const ForgotPassword = hooksObserver(({ classes, intl: { formatMessage } }) => {
  const {
    authStore: { forgotPassword }
  } = useContext(StoresContext);

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitForm = async event => {
    event.preventDefault();
    try {
      setErrorMessage("");
      setSending(true);
      await forgotPassword(email);
      setSent(!sent);
    } catch (error) {
      setErrorMessage(formatMessage(messages.forgotpassmailError));
      setSending(false);
    }
  };

  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.forgotpasstitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {(!sent && (
          <form id="form" onSubmit={submitForm}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="email"
                  label={formatMessage(messages.forgotpassemail)}
                  type="email"
                  fullWidth
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.formButtons}
              container
              direction="row-reverse"
              spacing={2}
            >
              {sending && (
                <Grid item>
                  <CircularProgress size={24} className={classes.progress} />
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={sending}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={submitForm}
                >
                  {formatMessage(messages.forgotpassconfirm)}
                </Button>
              </Grid>

              <Grid item>
                <Typography color={"error"}>{errorMessage}</Typography>
              </Grid>
            </Grid>
          </form>
        )) || (
          <div>
            <Typography>
              {formatMessage(messages.forgotpassmailsent)}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(useForgotPasswordStyles)(ForgotPassword));
