import { defineMessages } from "react-intl";

export default defineMessages({
  docs: {
    id: "header.docs",
    defaultMessage: "Documentatie"
  },
  logout: {
    id: "header.logout",
    defaultMessage: "Afmelden"
  },
  login: {
    id: "header.login",
    defaultMessage: "Aanmelden"
  },
  dashboard: {
    id: "header.dashboard",
    defaultMessage: "Dashboard"
  },
  forgotpass: {
    id: "header.forgotpass",
    defaultMessage: "Paswoord vergeten"
  },
  resetpass: {
    id: "header.resetpass",
    defaultMessage: "Paswoord instellen"
  },
  forgotpasstab: {
    id: "header.forgotpasstab",
    defaultMessage: "Nieuw paswoord aanvragen"
  },
  resetpasstab: {
    id: "header.resetpasstab",
    defaultMessage: "Paswoord opnieuw instellen"
  }
});
